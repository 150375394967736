// assets
import { ChromeOutlined, QuestionOutlined } from '@ant-design/icons';

// icons
const icons = {
  ChromeOutlined,
  QuestionOutlined
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const setting = {
    id: 'setting',
    title: '',
    type: 'group',
    children: [
      {
        id: 'region',
        title: 'Region',
        type: 'item',
        url: 'setting/region',
        icon: icons.CalendarOutlined,
        breadcrumbs: true
      },
      {
        id: 'location',
        title: 'Location',
        type: 'item',
        url: 'setting/location',
        icon: icons.CalendarOutlined,
        breadcrumbs: true
      },
    ]
  };

export default setting;